import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';

import HeaderSection from '../views/ProgramBinaKerjaya/HeaderSection';
import PerksSection from '../views/ProgramBinaKerjaya/PerksSection';

import Footer from '../components/Footer';
import KelayakanSection from '../views/ProgramBinaKerjaya/KelayakanSection';
import HeaderSeo from '../components/HeaderSeo';
import JomUpskillBanner from '../views/ProgramBinaKerjaya/JomUpskillBanner';
import ProgramSection from '../views/ProgramBinaKerjaya/ProgramSection';
import ProsesPendaftaran from '../views/ProgramBinaKerjaya/ProsesPendaftaran';
import FaqSection from '../views/ProgramBinaKerjaya/FaqSection';
import WhatsappBanner from '../views/ProgramBinaKerjaya/WhatsappBanner';

const Container = styled.div`
  min-height: 100vh;
`;

const ProgramBinaKerjaya = ({ location }) => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= 730) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <HeaderSeo
        title="GoGet x airasia academy x Perkeso | Program Bina Kerjaya"
        description="GoGet is an on demand workforce technology powered by a network of verified people, called GoGetters, to do work. We connect businesses to workers instantly."
        keywords="goget, Program Bina Kerjaya"
        pathname={location.pathname}
      />
      <Container>
        <HeaderSection isMobile={isMobile} />
        <PerksSection />
        <KelayakanSection />
        <JomUpskillBanner />
        <ProgramSection />
        <ProsesPendaftaran />
        <JomUpskillBanner />
        <FaqSection />
        <WhatsappBanner />
        <Footer />
      </Container>
    </>
  );
};

export default ProgramBinaKerjaya;
