import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const Container = styled.section`
  background-color: ${colors.greyLight};
  padding: 2rem 0;
  margin-top: 3rem;
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const SectionTitle = styled.h5`
  font-size: ${fontSize.oneHalfRem};
  text-align: center;
  margin: 0;
  padding-bottom: 1rem;

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.twoRem};
  }
`;
const QandAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;
const CardContent = styled.div`
  padding: 2rem;
`;
const QuestionText = styled.p`
  margin: 0;
  font-size: ${fontSize.xxLarge};
  font-weight: 600;

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.oneHalfRem};
  }
`;
const StyledList = styled.ol`
  padding: 1rem 0.5rem;
  padding-left: 1.5rem;
  margin: 0;
`;
const AnswerText = styled.li`
  font-size: ${fontSize.xLarge};
  line-height: 24px;
`;

const FaqSection = () => {
  return (
    <Container>
      <Content>
        <SectionTitle>FAQ's</SectionTitle>
        <QandAWrapper>
          <Card>
            <CardContent>
              <QuestionText>Di manakah kursus akan diadakan?</QuestionText>
              <StyledList>
                <AnswerText>
                  Lembah Klang -{' '}
                  <a
                    href="https://maps.app.goo.gl/GNEFphd6mhBcNLoi8"
                    target="_blank"
                    rel="noreferrer noopener"
                    style={{ color: colors.black }}
                  >
                    Air Asia Academy, KL Sentral
                  </a>
                </AnswerText>
              </StyledList>
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <QuestionText>Bilakah kursus akan dijalankan?</QuestionText>
              <StyledList>
                <AnswerText>
                  Tarikh kursus akan diaturkan selepas anda berjaya menerima
                  Surat Kelulusan (Guarantee Letter) daripada Pihak Perkeso
                  selepas anda membuka akaun Kerjaya Madani, dalam masa 14 hari.
                </AnswerText>
                <AnswerText>
                  Pihak GoGet akan menghubungi peserta yang berjaya dalam masa
                  terdekat untuk mengaturkan tarikh kursus anda.
                </AnswerText>
                <AnswerText>
                  Kursus akan diadakan pada hari Isnin-Jumaat pada pukul 10 pagi
                  - 6 petang (tertakluk kepada perubahan)
                </AnswerText>
              </StyledList>
            </CardContent>
          </Card>
        </QandAWrapper>
      </Content>
    </Container>
  );
};

export default FaqSection;
