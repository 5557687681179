import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';

import BannerImage from './assets/bannerImage.png';

import colors from '../../assets/colors';
import DaftarSekarangButton from '../../components/ProgramBinaKerjaya/DaftarSekarangButton';
import fontSize from '../../assets/fontSize';

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const StyledCard = styled(Card)`
  margin: 2rem 0;
  background-color: ${colors.secondary};
`;
const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  @media screen and (min-width: 730px) {
    flex-direction: row;
    gap: 1rem;
  }
`;
const ImageWrapper = styled.div`
  padding: 1rem 1rem 1rem;
`;
const HeroImage = styled.img`
  height: 200px;
`;
const TextSection = styled.div`
  text-align: center;
  padding: 0 1rem;
  flex: 1;

  @media screen and (min-width: 730px) {
    text-align: left;
    padding: 1rem;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;

  @media screen and (min-width: 730px) {
    padding: 2rem;
  }
`;
const BannerTitle = styled.p`
  font-size: ${fontSize.oneHalfRem};
  font-weight: 600;
  color: ${colors.white};
  margin: 0;

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.twoRem};
  }
`;
const BannerSubtitle = styled.p`
  font-size: ${fontSize.xxLarge};
  font-weight: 600;
  color: ${colors.white};
  margin-top: 0.5rem;
`;

const JomUpskillBanner = () => {
  return (
    <Container>
      <StyledCard>
        <CardContent>
          <ImageWrapper>
            <HeroImage src={BannerImage} alt="Banner image" />
          </ImageWrapper>
          <TextSection>
            <BannerTitle>Jom upskill sekarang!</BannerTitle>
            <BannerSubtitle>
              Jangan terlepas peluang ini. Slot terhad
            </BannerSubtitle>
          </TextSection>
          <ButtonWrapper>
            <DaftarSekarangButton />
          </ButtonWrapper>
        </CardContent>
      </StyledCard>
    </Container>
  );
};

export default JomUpskillBanner;
