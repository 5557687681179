import React from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';

import colors from '../../assets/colors';

const StyledButton = styled(Button)`
  && {
    background-color: ${colors.primary};
    color: ${colors.white};
    transition: opacity 0.3s ease-in-out;
    padding: 0.75rem 1rem;
    font-weight: 600;

    :hover {
      opacity: 0.8;
    }
  }
`;

const DaftarSekarangButton = props => {
  const { noSekarang } = props;
  return (
    <a
      href="https://bit.ly/programbinakerjayalp"
      target="_blank"
      rel="noreferrer"
    >
      <StyledButton variant="contained" disableElevation>
        Daftar {!noSekarang ? 'Sekarang' : ''}
      </StyledButton>
    </a>
  );
};

export default DaftarSekarangButton;
