import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';

import DaftarSekarangButton from '../../components/ProgramBinaKerjaya/DaftarSekarangButton';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 1.5rem;
`;
const StyledCard = styled(Card)`
  background-color: ${colors.appleGreen};
  padding: 1rem;
  margin-top: 2rem;

  @media screen and (min-width: 768px) {
    padding: 1rem 2rem;
  }
`;
const Title = styled.h5`
  margin: 0;
  padding: 1rem 0;
  font-size: ${fontSize.oneHalfRem};
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: ${fontSize.twoRem};
  }
`;
const StepsWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const StepCard = styled.div`
  background-color: ${colors.white};
  padding: 1rem 1.5rem;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
`;
const StepChip = styled.div`
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: ${fontSize.xxLarge};
  width: 24px;
  height: 24px;

  @media screen and (min-width: 768px) {
    width: 32px;
    height: 32px;
    font-size: ${fontSize.oneHalfRem};
  }
`;
const TextContainer = styled.div`
  flex: 1;
`;
const StepTitle = styled.h6`
  margin: 0;
  font-size: ${fontSize.large};
  font-weight: 600;

  @media screen and (min-width: 768px) {
    font-size: ${fontSize.xxLarge};
  }
`;
const StepText = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  font-size: ${fontSize.large};

  @media screen and (min-width: 768px) {
    font-size: ${fontSize.xxLarge};
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ProsesPendaftaran = () => {
  return (
    <Container>
      <StyledCard>
        <Title>Proses pendaftaran Program Bina Kerjaya</Title>
        <StepsWrapper>
          <StepCard>
            <StepChip>1</StepChip>
            <TextContainer>
              <StepTitle>Isi Google Form di sini</StepTitle>
              <StepText>
                Klik butang [Daftar Sekarang] untuk mengisi maklumat anda
              </StepText>
            </TextContainer>
            <ButtonWrapper>
              <DaftarSekarangButton />
            </ButtonWrapper>
          </StepCard>
          <StepCard>
            <StepChip>2</StepChip>
            <TextContainer>
              <StepTitle>Pendaftaran akaun Kerjaya Madani</StepTitle>
              <StepText>
                Ikuti langkah-langkah yang diberikan melalui e-mel untuk membuka
                akaun ATAU anda boleh mengikuti sesi taklimat secara online
                untuk proses pendaftaran
              </StepText>
            </TextContainer>
          </StepCard>
          <StepCard>
            <StepChip>3</StepChip>
            <TextContainer>
              <StepTitle>Surat Kelulusan</StepTitle>
              <StepText>
                Peserta yang berjaya akan mendapat Surat Kelulusan daripada
                PERKESO.
              </StepText>
            </TextContainer>
          </StepCard>
        </StepsWrapper>
      </StyledCard>
    </Container>
  );
};

export default ProsesPendaftaran;
