import React from 'react';
import styled from 'styled-components/macro';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Logo from './assets/logoMobile.png';

import DaftarSekarangButton from '../../components/ProgramBinaKerjaya/DaftarSekarangButton';

import fontSize from '../../assets/fontSize';
import colors from '../../assets/colors';

const BannerDesktop = './assets/bannerDesktop.png';
const BannerMobile = './assets/bannerMobile.png';

const Header = styled.header`
  position: relative;
  padding: 1rem 1.5rem;
  height: 850px;

  @media screen and (min-width: 730px) {
    height: 612px;
  }
`;
const BannerImageContainer = styled.div`
  z-index: -1;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
`;
const LogoImage = styled.img`
  width: 90%;

  @media screen and (min-width: 730px) {
    width: 40%;
  }
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
`;
const Title = styled.h2`
  font-size: ${fontSize.twoRem};
  color: ${colors.white};
  margin: 0;
  margin-top: 1rem;

  @media screen and (min-width: 730px) {
    font-size: 48px;
    max-width: 50%;
  }
`;
const TealHighlight = styled.span`
  color: ${colors.primary};
`;
const Subtitle = styled.h4`
  font-size: ${fontSize.oneHalfRem};
  color: ${colors.white};
  margin: 0;
  margin-top: 1rem;
`;
const ButtonWrapper = styled.div`
  margin-top: 1rem;
`;

const HeaderSection = props => {
  const { isMobile } = props;

  return (
    <Header>
      <Content>
        <Link to="/">
          <LogoImage src={Logo} alt="Logo" />
        </Link>
        <BannerImageContainer hidden={isMobile}>
          <StaticImage
            src={BannerDesktop}
            loading="eager"
            quality={100}
            style={{ height: '100%' }}
            alt="Banner"
          />
        </BannerImageContainer>
        <BannerImageContainer hidden={!isMobile}>
          <StaticImage
            src={BannerMobile}
            loading="eager"
            quality={100}
            style={{ height: '100%' }}
            alt="Banner"
          />
        </BannerImageContainer>
        <Title>
          Hadiri kursus upskilling bernilai{' '}
          <TealHighlight>RM4,000</TealHighlight> secara percuma!
        </Title>
        <Subtitle>Serta RM300 elaun + sijil digital</Subtitle>
        <ButtonWrapper>
          <DaftarSekarangButton />
        </ButtonWrapper>
      </Content>
    </Header>
  );
};

export default HeaderSection;
