import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';

import LatihanProfesional from './assets/latihanProfesional.png';
import Rm300 from './assets/rm300.png';
import Digital from './assets/digital.png';

import fontSize from '../../assets/fontSize';
import colors from '../../assets/colors';

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
`;
const SectionTitle = styled.h5`
  margin: 0;
  font-size: ${fontSize.twoRem};
  text-align: center;
`;
const PerkGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 1.5rem;
  margin-top: 3rem;
`;
const PerkCard = styled(Card)`
  grid-column: span 12;
  padding-bottom: 1rem;

  @media screen and (min-width: 730px) {
    grid-column: span 4;
  }
`;
const PerkImage = styled.img`
  width: 100%;
`;
const PerkText = styled.p`
  font-size: ${fontSize.oneHalfRem};
  font-weight: 600;
  margin: 0;
  padding: 1.5rem;
  text-align: center;
`;
const TealHighlight = styled.span`
  color: ${colors.primary};
`;

const PerksSection = () => {
  return (
    <Container>
      <SectionTitle>Kelebihan menyertai Program Bina Kerjaya</SectionTitle>
      <PerkGrid>
        <PerkCard>
          <PerkImage src={LatihanProfesional} alt="Latihan profesional" />
          <PerkText>
            Latihan Profesional <br />
            <TealHighlight>ditaja sepenuhnya</TealHighlight>
          </PerkText>
        </PerkCard>
        <PerkCard>
          <PerkImage src={Rm300} alt="Elaun RM300" />
          <PerkText>
            Elaun sebanyak <br />
            <TealHighlight>RM300</TealHighlight>
          </PerkText>
        </PerkCard>
        <PerkCard>
          <PerkImage src={Digital} alt="Sijil digital" />
          <PerkText>
            <TealHighlight>Sijil Digital</TealHighlight> <br />
            disediakan
          </PerkText>
        </PerkCard>
      </PerkGrid>
    </Container>
  );
};

export default PerksSection;
