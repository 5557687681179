import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';

import AccessTime from '@material-ui/icons/AccessTime';
import Description from '@material-ui/icons/Description';
import Place from '@material-ui/icons/Place';

import ProgramOne from './assets/programOne.png';
import ProgramTwo from './assets/programTwo.png';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const Container = styled.section`
  background-color: ${colors.greyLight};
  padding: 2rem 0;
`;
const Content = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.5rem;
`;
const SectionTitle = styled.h5`
  font-size: ${fontSize.oneHalfRem};
  text-align: center;
  margin: 0;
  padding-bottom: 1rem;

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.twoRem};
  }
`;
const TealHighlight = styled.span`
  color: ${colors.primary};
`;
const StrikeThrough = styled.span`
  text-decoration: line-through;
`;
const StyledCard = styled(Card)`
  margin: 1.5rem 0;
  padding: 1rem;

  @media screen and (min-width: 730px) {
    padding: 2rem;
  }
`;
const Header = styled.div`
  padding: 1rem;
  background-color: ${colors.appleGreen};
  border-radius: 4px;
  display: flex;
  gap: 1rem;
  flex-direction: column-reverse;

  @media screen and (min-width: 730px) {
    padding: 0 1.5rem;
    padding-top: 1rem;
    flex-direction: row;
  }
`;
const HeroImage = styled.img`
  height: 150px;

  @media screen and (min-width: 730px) {
    height: 200px;
  }
`;
const TextContainer = styled.div`
  flex: 1;
`;
const FlexText = styled.div`
  display: flex;
  gap: 0.5rem;
`;
const MainText = styled.p`
  margin: 0;
  font-weight: 600;
  font-size: ${fontSize.xxLarge};

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.twoRem};
    font-weight: 600;
  }
`;
const SmallText = styled.span`
  @media screen and (min-width: 730px) {
    font-size: ${fontSize.oneHalfRem};
  }
`;
const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0.75rem 0;
`;
const DetailsText = styled.p`
  margin: 0;
  font-size: ${fontSize.large};

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.xxLarge};
  }
`;
const ProgramPerksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;
const ProgramPerkRow = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
  background-color: ${colors.greyLight};
  border-radius: 4px;
`;
const ProgramPerkText = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: ${fontSize.large};

  @media screen and (min-width: 730px) {
    font-size: ${fontSize.xxLarge};
  }
`;

const ProgramSection = () => {
  return (
    <Container>
      <Content>
        <SectionTitle>
          Pilih antara 2 kursus yang menarik secara{' '}
          <TealHighlight>PERCUMA!</TealHighlight> <br />
          Harga biasa: <StrikeThrough>RM4,000</StrikeThrough>
        </SectionTitle>
        <StyledCard>
          <Header>
            <TextContainer>
              <FlexText>
                <MainText>1.</MainText>
                <MainText>
                  Program Kemahiran Pemasaran{' '}
                  <SmallText>(Digital Marketing & Sales Excellence)</SmallText>
                </MainText>
              </FlexText>
              <DetailsRow>
                <AccessTime />
                <DetailsText>Program selama 5 Hari</DetailsText>
              </DetailsRow>
              <DetailsRow>
                <Place />
                <DetailsText>Lembah Klang</DetailsText>
              </DetailsRow>
            </TextContainer>
            <HeroImage src={ProgramOne} alt="Program One" />
          </Header>
          <DetailsRow>
            <Description />
            <DetailsText>Program silibus:</DetailsText>
          </DetailsRow>
          <ProgramPerksWrapper>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Asas Pemasaran Digital (Digital Marketing
                Overview)
              </ProgramPerkText>
            </ProgramPerkRow>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Kemahiran Insaniah (Soft Skill) - K.A.S.H
                Knowledge, Attitude, Skills & Habit
              </ProgramPerkText>
            </ProgramPerkRow>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Perkhidmatan Pelanggan yang Efektif
                (Effective Customer Service)
              </ProgramPerkText>
            </ProgramPerkRow>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Kemahiran Telejualan (Sell Like A Pro -
                Telesales Skills)
              </ProgramPerkText>
            </ProgramPerkRow>
          </ProgramPerksWrapper>
        </StyledCard>
        <StyledCard>
          <Header>
            <TextContainer>
              <FlexText>
                <MainText>2.</MainText>
                <MainText>
                  Program Kemahiran Insaniah Digital{' '}
                  <SmallText>(Digital Soft Skill Programme)</SmallText>
                </MainText>
              </FlexText>
              <DetailsRow>
                <AccessTime />
                <DetailsText>Program selama 5 Hari</DetailsText>
              </DetailsRow>
              <DetailsRow>
                <Place />
                <DetailsText>Lembah Klang</DetailsText>
              </DetailsRow>
            </TextContainer>
            <HeroImage src={ProgramTwo} alt="Program Two" />
          </Header>
          <DetailsRow>
            <Description />
            <DetailsText>Program silibus:</DetailsText>
          </DetailsRow>
          <ProgramPerksWrapper>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Kemahiran Insaniah (Soft Skill) - K.A.S.H
                Knowledge, Attitude, Skills, Habit
              </ProgramPerkText>
            </ProgramPerkRow>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Alat Produktiviti Google Workspace (Google
                Workspace Productivity Tools)
              </ProgramPerkText>
            </ProgramPerkRow>
            <ProgramPerkRow>
              <ProgramPerkText>
                <span>&bull;</span> Pertolongan Cemas (First Aid)
              </ProgramPerkText>
            </ProgramPerkRow>
          </ProgramPerksWrapper>
        </StyledCard>
      </Content>
    </Container>
  );
};

export default ProgramSection;
