import React from 'react';
import styled from 'styled-components/macro';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

import QuestionAnswer from '@material-ui/icons/QuestionAnswer';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
`;
const StyledCard = styled(Card)`
  background-color: ${colors.appleGreen};
  min-height: 120px;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    padding: 2rem 3rem;
    gap: 2rem;
  }
`;
const TextSection = styled.p`
  flex: 1;
  margin: 0 1rem;
  text-align: center;
  font-size: ${fontSize.oneHalfRem};
  font-weight: 600;

  @media screen and (min-width: 768px) {
    text-align: left;
    margin: 0;
  }
`;
const StyledButton = styled(Button)`
  && {
    background-color: ${colors.primary};
    color: ${colors.white};
    transition: opacity 0.3s ease-in-out;
    padding: 0.75rem 1rem;
    font-weight: 600;

    :hover {
      opacity: 0.8;
    }
  }
`;

const WhatsappBanner = () => {
  return (
    <Container>
      <StyledCard>
        <QuestionAnswer style={{ fontSize: '47px' }} />
        <TextSection>
          Ada soalan? Klik butang untuk menghubungi kami
        </TextSection>
        <a
          href="https://chat.whatsapp.com/Hg7t9ECihzrL1YA7lkwbiY"
          target="_blank"
          rel="noreferrer noopener"
        >
          <StyledButton>Hubungi Kami</StyledButton>
        </a>
      </StyledCard>
    </Container>
  );
};

export default WhatsappBanner;
