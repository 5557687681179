import React from 'react';
import styled from 'styled-components/macro';

import Card from '@material-ui/core/Card';
import colors from '../../assets/colors';

import Malaysia from './assets/malaysia.png';
import Age from './assets/ageIcon.png';
import Sijil from './assets/sijil.png';
import Perkeso from './assets/perkeso.png';

import fontSize from '../../assets/fontSize';

const Container = styled.section`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem;
`;
const StyledCard = styled(Card)`
  background-color: ${colors.appleGreen};
  padding: 2rem;
`;
const Title = styled.h5`
  margin: 0;
  font-size: ${fontSize.twoRem};
  font-weight: 600;
  text-align: center;
`;
const TextContent = styled.p`
  padding: 0 1rem;
  font-size: ${fontSize.xxLarge};
  text-align: center;
`;
const KelayakanGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 2rem;
`;
const KelayakanBox = styled.div`
  grid-column: span 12;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (min-width: 730px) {
    grid-column: span 3;
    flex-direction: column;
    justify-content: center;
  }
`;
const KelayakanIcon = styled.img`
  width: 100%;
  max-width: 84px;
`;
const KelayakanText = styled.p`
  font-size: ${fontSize.xxLarge};
  font-weight: 600;
  text-align: center;
`;

const KelayakanSection = () => {
  return (
    <Container>
      <StyledCard>
        <Title>Kelayakan</Title>
        <TextContent>
          Program Bina Kerjaya khas untuk individu yang bekerja tidak formal
          (pekerja gig), seperti Food Rider, peniaga, bekerja sendiri, atau
          Freelancer dan mematuhi kelayakan berikut :-
        </TextContent>
        <KelayakanGrid>
          <KelayakanBox>
            <KelayakanIcon src={Malaysia} alt="Malaysian" />
            <KelayakanText>Warganegara Malaysia</KelayakanText>
          </KelayakanBox>
          <KelayakanBox>
            <KelayakanIcon src={Age} alt="Age" />
            <KelayakanText>Berumur dari 16 hingga 40 tahun</KelayakanText>
          </KelayakanBox>
          <KelayakanBox>
            <KelayakanIcon src={Sijil} alt="Sijil" />
            <KelayakanText>
              Memiliki tahap pengajian SPM/SKM & ke bawah
            </KelayakanText>
          </KelayakanBox>
          <KelayakanBox>
            <KelayakanIcon src={Perkeso} alt="Perkeso" />
            <KelayakanText>
              Tiada caruman PERKESO dalam masa 6 bulan yang lalu
            </KelayakanText>
          </KelayakanBox>
        </KelayakanGrid>
      </StyledCard>
    </Container>
  );
};

export default KelayakanSection;
